
import defaultMeta from "@/mixins/defaultMeta"
import { mapState } from "vuex"

export default {
    components: {
        ImpersonateHeader: () => import("@/components/admin/ImpersonateHeader"),
        SVGGradient: () => import("@/components/base/design/SVGGradient"),
        Login: () => import("@/components/auth/Login"),
        CookieInformation: () =>
            import("@/components/layout/CookieInformation"),
    },
    mixins: [defaultMeta],
    computed: {
        ...mapState({
            showCookieConsent: (state) => state.cookie.showCookieConsent,
        }),
    },
}
